import React from 'react';

import { Row, Col, Typography } from 'antd';
import { ApplicationData } from '@bwb-buildings-mono/entities';
import * as UI from '@bwb-buildings-mono/ui';
const { Paragraph, Text, Title } = Typography;

const PHONE_NUMBER = '+1(818)722-2116';
const EMAIL = 'leasing@1050allen.com';
const ADDRESS_NAME = '';
const ADDRESS_1 = '1046 / 1050 Allen Avenue';
const ADDRESS_2 = 'Glendale, CA 91201';

const features = {
  sentence: (
    <div style={{ fontSize: '18px', lineHeight: '18px', textAlign: 'center' }}>
      <Paragraph>Bright, open floor plans.</Paragraph>
      <Paragraph>
        <Text mark strong>
          Full-size washer and dryer
        </Text>{' '}
        in every unit.
      </Paragraph>
      <Paragraph>
        <Text mark strong>
          Upgraded stainless steel appliances
        </Text>
        .
      </Paragraph>
      <Paragraph>Bright new laminate wood flooring and new carpet.</Paragraph>
      <Paragraph>Granite kitchen counters.</Paragraph>
      <Paragraph>Central heat and air conditioning</Paragraph>
      <Paragraph>Recessed Lighting</Paragraph>
      <Paragraph>
        Separate water system and{' '}
        <Text mark strong>
          individual water heaters
        </Text>{' '}
        in each unit
      </Paragraph>
    </div>
  ),
  images: [
    {
      src: 'assets/pics/apt1.jpg',
      caption: '',
      thumb: 'assets/pics/apt1.jpg',
    },
    {
      src: 'assets/pics/apt2.jpg',
      caption: '',
      thumb: 'assets/pics/apt2.jpg',
    },
    {
      src: 'assets/pics/apt3.jpg',
      caption: '',
      thumb: 'assets/pics/apt3.jpg',
    },
    {
      src: 'assets/pics/apt4.jpg',
      caption: '',
      thumb: 'assets/pics/apt4.jpg',
    },
    {
      src: 'assets/pics/apt5.jpg',
      caption: '',
      thumb: 'assets/pics/apt5.jpg',
    },
  ],
};

export const AppData: ApplicationData = {
  footer: (
    <div>
      <div>
        <Row>
          <Col md={24} xs={0}>
            <div>1046 Allen Avenue, Glendale, CA 91201</div>
            <div>1050 Allen Avenue, Glendale, CA 91201</div>
            <div>
              <a href={`tel:${PHONE_NUMBER}`}>{PHONE_NUMBER}</a>
            </div>
          </Col>
          <Col md={0} xs={24}>
            {ADDRESS_NAME}
            <br />
            {ADDRESS_1}
            <br />
            {ADDRESS_2}
            <br />
            <a href={`tel:${PHONE_NUMBER}`}>{PHONE_NUMBER}</a>
          </Col>
        </Row>
      </div>
      <div style={{ fontSize: '80%', marginTop: '24px' }}>
        <div>
          {ADDRESS_NAME} is located in or around EAGLE ROCK, BURBANK, GLENDALE
        </div>
        <div>Thank You For Looking, We Look Forward to Helping You!</div>
      </div>
    </div>
  ),
  sections: [
    {
      element: UI.Logo,
      props: {
        logo: (
          <div>
            <Title level={2} style={{ margin: 0 }}>
              1046 / 150 Allen Ave.
            </Title>
          </div>
        ),
        address: (
          <div style={{ lineHeight: 1.2 }}>
            <div style={{ fontSize: '110%' }}>{ADDRESS_NAME}</div>
            <div style={{ fontSize: '80%' }}>{ADDRESS_1}</div>
            <div style={{ fontSize: '80%' }}>{ADDRESS_2}</div>
            <div style={{ fontSize: '80%' }}>
              <a href={`tel:${PHONE_NUMBER}`}>{PHONE_NUMBER}</a>
            </div>
          </div>
        ),
      },
    },
    {
      isNav: true,
      element: UI.Nav,
      props: {
        logo: {
          logo: (
            <img
              alt="logo"
              style={{ height: '50px' }}
              src="./assets/logo.png"
            />
          ),
          address: (
            <div style={{ lineHeight: 1 }}>
              <div style={{ fontSize: '110%' }}>{ADDRESS_NAME}</div>
              <div style={{ fontSize: '80%' }}>{ADDRESS_1}</div>
              <div style={{ fontSize: '80%' }}>{ADDRESS_2}</div>
              <div style={{ fontSize: '80%' }}>{PHONE_NUMBER}</div>
            </div>
          ),
        },
        rightSide: (
          <UI.BookTourButton phone_number={PHONE_NUMBER} email={EMAIL} />
        ),
        rightSideModal: (
          <div>
            <Paragraph>
              {/* <UI.VirtualToursButton tours={tours}/> */}
            </Paragraph>
            <Paragraph>
              <UI.BookTourButton phone_number={PHONE_NUMBER} email={EMAIL} />
            </Paragraph>
          </div>
        ),
      },
    },
    {
      element: UI.HeroImage,
      props: {
        image: './assets/front.jpg',
      },
    },
    {
      element: UI.Tagline,
      props: {
        tagline:
          'Newly renovated apartment townhouses in Glendale. Central location. Quiet neighborhood.',
        undertag: (
          <div>
            {/* <UI.VirtualToursButton tours={tours}/> */}
            <UI.BookTourButton phone_number={PHONE_NUMBER} email={EMAIL} />
          </div>
        ),
      },
    },
    {
      element: UI.SectionTextCarousel,
      label: UI.NAV_ITEM.UNITS,
      nav: 'Units',
      props: {
        title: 'About Our Apartments',
        sections: [features],
      },
    },
    {
      element: UI.SectionImages,
      label: UI.NAV_ITEM.FLOORPLANS,
      nav: 'Floorplans',
      props: {
        title: 'Floorplans',
        images: [
          {
            name: 'Floorplan 1',
            info: (
              <div style={{ lineHeight: '20px' }}>
                <div>2 Bedroom + 2.5 Bathroom Townhome</div>
                <div>~960 Sq Ft + Patio</div>
              </div>
            ),
            color: '#1890ff',
            image: './assets/floorplans/floorplan_1.jpg',
          },
          {
            name: 'Floorplan 2',
            info: (
              <div style={{ lineHeight: '20px' }}>
                <div>2 Bedroom + 2 Bathroom Townhome</div>
                <div>~960 Sq Ft + Patio</div>
              </div>
            ),
            color: '#1890ff',
            image: './assets/floorplans/floorplan_2.jpg',
          },
        ],
      },
    },
    {
      element: UI.SectionTitle,
      label: UI.NAV_ITEM.CONTACT,
      nav: 'Tour',
      props: {
        title: 'Tour',
      },
    },
    {
      element: UI.SectionSideBySide,
      props: {
        leftSide: (
          <div>
            <h3>Schedule a tour with our on-site manager:</h3>
            <div style={{ marginTop: 24 }}>
              <h1 style={{}}>
                <a href={`tel:${PHONE_NUMBER}`}>{PHONE_NUMBER}</a>
              </h1>
              <div style={{ fontSize: '80%' }}>Call anytime!</div>
            </div>
            <div style={{ marginTop: 24 }}>
              <h1 style={{}}>
                <a href={`mailto:${EMAIL}`}>{EMAIL}</a>
              </h1>
              <div style={{ fontSize: '80%' }}>
                Email us what you are looking for and some times that work for
                you.
              </div>
            </div>
            <div style={{ marginTop: 24 }}>
              <Paragraph>
                {/* <UI.VirtualToursButton tours={tours}/> */}
              </Paragraph>
              <Paragraph>
                <UI.BookTourButton phone_number={PHONE_NUMBER} email={EMAIL} />
              </Paragraph>
            </div>
          </div>
        ),
        rightSide: (
          <iframe
            title="Property Location Map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14895.536029688861!2d-118.30669915475109!3d34.17091096997753!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2c006adc22ba9%3A0xf5604d0d779c0d5a!2s1050%20Allen%20Ave%2C%20Glendale%2C%20CA%2091201!5e0!3m2!1sen!2sus!4v1602691797823!5m2!1sen!2sus"
            width="400"
            height="400"
            frameBorder={0}
            style={{ border: '1px solid #CCC' }}
          ></iframe>
        ),
      },
    },
  ],
};
